import { Get } from "../..";
import { ApiResponse } from "../../models/common/apiResponse";
import { CursorPaginationResponse } from "../../models/common/cursorPaginationResponse";
import { IMarketModel } from "../../models/market/marketModel";

export const MarketService = {
    /**
   * Post 전체 조회
   * @returns
   */
  findAll: async (
    params: string,
  ): Promise<ApiResponse<CursorPaginationResponse<IMarketModel[]>>> => {
    return Get(`/open/api/market?${params}`);
  },
}