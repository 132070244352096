import { Route, Routes } from "react-router-dom";
import CommunityDetailPage from "../pages/community/detail/CommunityDetailPage";
import CommunityPage from "../pages/community/list/CommunityPage";
import MainPage from "../pages/main/MainPage";
import MarketDetailPage from "../pages/market/detail/MarketDetailPage";
import MarketPage from "../pages/market/list/MarketPage";
import RidingDetailPage from "../pages/riding/detail/RidingDetailPage";
import RidingPage from "../pages/riding/list/RidingPage";
import FaqListPage from "../pages/support/faq/FaqListPage";
import NoticeListPage from "../pages/support/notice/list/NoticeListPage";
import Layout from "./layout/Layout";

function Router() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<MainPage />}></Route>
        <Route path="/community" element={<CommunityPage />}></Route>
        <Route path="/community/:id" element={<CommunityDetailPage />}></Route>

        <Route path="/market" element={<MarketPage />}></Route>
        <Route path="/market/:id" element={<MarketDetailPage />}></Route>

        <Route path="/riding" element={<RidingPage />}></Route>
        <Route path="/riding/:id" element={<RidingDetailPage />}></Route>

        <Route path="/support/notice" element={<NoticeListPage />}></Route>
        <Route path="/support/faq" element={<FaqListPage />}></Route>
      </Route>
    </Routes>
  );
}

export default Router;
