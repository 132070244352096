import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { CursorPaginationMeta } from "../../../../apis/models/common/cursorPaginationResponse";
import { MarketService } from "../../../../apis/services/market/marketService";
import useIntersect from "../../../../hooks/useIntersect";
import { marketListState } from "../../../../states/marketAtom";
import MarketListItem from "./MarketListItem";

function MarketListView() {
  const [isFetching, setIsFetching] = useState(false);
  const [meta, setMeta] = useState<CursorPaginationMeta>({
    count: 0,
    isNext: false,
  });
  const [items, setItems] = useRecoilState(marketListState);

  useEffect(() => {
    findAll();
  }, []);

  const targetRef = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (meta.isNext && !isFetching) {
      findAll();
    }
  });

  const findAll = async () => {
    try {
      setIsFetching(true);
      if (items.length > 0 && meta.isNext) {
        const last = items[items.length - 1];
        const response = await MarketService.findAll(
          `cursor=${last.id}&size=30`
        );
        if (response.status === "OK") {
          setItems((prev) => [...prev, ...response.data.items]);
          setMeta({ ...response.data.meta });
        }
      } else {
        const response = await MarketService.findAll(`&size=30`);
        if (response.status === "OK") {
          setItems([...response.data.items]);
          setMeta({ ...response.data.meta });
        }
      }
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
    }
  };

  return (
    <div>
      <div className="grid md:grid-cols-3 grid-cols-2 gap-8 md:mx-0 mx-3">
        {items.map((item, index) => (
          <MarketListItem
            key={index}
            item={item}
            onClick={() => {
              //   navigate(`/market/${item.id}`);
            }}
          />
        ))}
      </div>
      <div ref={targetRef}></div>
    </div>
  );
}

export default MarketListView;
