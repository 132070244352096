import { IFaqModel } from "../../../../apis/models/support/faq/faqModel";
import Accordion from "../../../../components/accordion/Accordion";

type Props = {
  item: IFaqModel;
};

function FaqListItem({ item }: Props) {
  return (
    <Accordion
      title={
        <div className="flex py-6">
          <div className="flex px-6">
            <div>
              <p className="text-primary font-bold text-lg mr-2">Q</p>
            </div>
            <div className="pt-0.5">
              <p className="font-medium text-[#212121]">{item.title}</p>
            </div>
          </div>
        </div>
      }
    >
      <div className="flex py-6 bg-gray-50">
        <div className="ml-[16px] flex px-6">
          <p className="text-primary font-bold text-lg mr-2">A</p>
          <div className="pt-0.5">
            <p className="font-medium text-[#424242]">{item.content}</p>
          </div>
        </div>
      </div>
    </Accordion>
  );
}

export default FaqListItem;
