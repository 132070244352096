import { IPostModel } from "../../../../apis/models/post/postModel";
import CustomImage from "../../../../components/image/CustomImage";

type Props = {
  item: IPostModel;
  onClick?: () => void;
};

function CommunityListItem({ item, onClick }: Props) {
  return (
    <div className="border p-4 rounded-lg shadow-lg ">
      {/* Profile */}
      <div className="flex gap-2 pb-4">
        <div>
          <CustomImage
            className="border aspect-square w-12 rounded-full object-cover"
            src={item.user.profileImageUrl}
          />
        </div>
        <div>
          <p className="text-md">{item.user.nickname}</p>
          <span className="py-[2px] px-[8px] rounded bg-yellow-400 text-sm">
            {item.user.level}km
          </span>
        </div>
      </div>
      <hr />
      <div className="mt-2">
        <div className="flex gap-2">
          <p className="w-full line-clamp-3">{item.content}</p>
          <div>
            {item.images.length > 0 && (
              <CustomImage
                className="rounded-xl overflow-hidden aspect-square object-cover w-[100px] shrink-0"
                src={item.images[0]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunityListItem;
