import FaqListView from "./components/FaqListView";

function FaqListPage() {
  return (
    <div>
      {/* Header */}
      <div className="mt-16 max-w-[768px] m-auto">
        <p className="text-[32px] font-semibold">안녕하세요,</p>
        <p className="text-[32px] font-semibold">무엇을 도와드릴까요?</p>
      </div>

      {/* Body */}
      <div className="my-8 max-w-[768px] m-auto">
        <FaqListView />
      </div>
    </div>
  );
}

export default FaqListPage;
