import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    InternalAxiosRequestConfig,
  } from "axios";
  import Logger from "../utils/logger";
  
  const httpInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    timeout: 60000,
  });
  
  // http method
  export const Get = async <T>(
    url: string,
    config?: AxiosRequestConfig<any>
  ): Promise<any> => {
    const response = await httpInstance.get<any>(url, config);
    return response.data;
  };
  
  export const Post = async <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Promise<any> => {
    const response = await httpInstance.post<any>(url, data, config);
    return response.data;
  };
  
  export const Put = async <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Promise<any> => {
    const response = await httpInstance.put<any>(url, data, config);
    return response.data;
  };
  
  export const Delete = async <T>(
    url: string,
    config?: AxiosRequestConfig<any>
  ): Promise<any> => {
    const response = await httpInstance.delete<any>(url, config);
    return response.data;
  };
  
  // Interceptor
  const onRequest = (
    config: InternalAxiosRequestConfig
  ): InternalAxiosRequestConfig => {
    Logger.debug("Interceptors onRequest", config.url);
    return config;
  };
  
  const onResponse = (response: AxiosResponse): AxiosResponse => {
    // Logger.debug("Interceptors onResponse", response.data);
    return response;
  };
  
  const onResponseError = async (
    error: AxiosError | Error
  ): Promise<AxiosError> => {
    Logger.debug("Interceptors onResponseError", error);
    return Promise.reject(error);
  };
  
  // request
  httpInstance.interceptors.request.use(onRequest);
  
  // response
  httpInstance.interceptors.response.use(onResponse, onResponseError);
  