import { Get } from "../..";
import { ApiResponse } from "../../models/common/apiResponse";
import { IFaqModel } from "../../models/support/faq/faqModel";
import { INoticeModel } from "../../models/support/notice/noticeModel";

export const SupportService = {
  findAllNotice: async (): Promise<ApiResponse<INoticeModel[]>> => {
    return Get("/api/notice");
  },
  findAllFaq: async (): Promise<ApiResponse<IFaqModel[]>> => {
    return Get("/api/faq");
  },
};
