import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { CursorPaginationMeta } from "../../../../apis/models/common/cursorPaginationResponse";
import { PostService } from "../../../../apis/services/post/postService";
import useIntersect from "../../../../hooks/useIntersect";
import { postListState } from "../../../../states/postAtom";
import RidingListItem from "./RidingListItem";

type Props = {
  type: string;
};

function RidingListView({ type }: Props) {
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const [meta, setMeta] = useState<CursorPaginationMeta>({
    count: 0,
    isNext: false,
  });
  const [items, setItems] = useRecoilState(postListState(type));

  useEffect(() => {
    findAll();
  }, []);

  const targetRef = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (meta.isNext && !isFetching) {
      findAll();
    }
  });

  const findAll = async () => {
    try {
      setIsFetching(true);
      if (items.length > 0 && meta.isNext) {
        const last = items[items.length - 1];
        const response = await PostService.findAll(
          `type=${type}&cursor=${last.id}&size=30`
        );
        if (response.status === "OK") {
          setItems((prev) => [...prev, ...response.data.items]);
          setMeta({ ...response.data.meta });
        }
      } else {
        const response = await PostService.findAll(`type=${type}`);
        if (response.status === "OK") {
          setItems([...response.data.items]);
          setMeta({ ...response.data.meta });
        }
      }
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
    }
  };

  return (
    <div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-6 md:mx-0 mx-3">
        {items.map((item, index) => (
          <RidingListItem
            key={index}
            item={item}
            onClick={() => {
              navigate(`/riding/${item.id}`);
            }}
          />
        ))}
      </div>
      <div>{items.length > 0 && <div ref={targetRef}></div>}</div>
    </div>
  );
}

export default RidingListView;
