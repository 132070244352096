import { memo, useEffect } from "react";

type Props = {
  className?: string | undefined;
  src?: string | undefined;
};

function CustomImage({ className, src }: Props) {
  useEffect(() => {
    cache();
  }, [src]);

  const cache = async () => {
    if (src) {
      new Promise((resolve, reject) => {
        const image = new Image();
        image.src = src;
        image.onload = resolve;
        image.onerror = reject;
      })
        .then(() => {})
        .catch(() => {});
    }
  };

  return (
    <img
      src={src ?? require("../../assets/images/common/no-image.jpeg")}
      className={className}
      alt="Image"
      onError={(e) =>
        (e.currentTarget.src = require("../../assets/images/common/no-image.jpeg"))
      }
    />
  );
}

export default memo(CustomImage);
