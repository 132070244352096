import NoticeListView from "./components/NoticeListView";

function NoticeListPage() {
  return (
    <div>
      {/* Header */}
      <section className="mt-16 max-w-[768px] m-auto">
        <div className="flex justify-center">
          <p className="text-[32px] font-semibold">공지사항</p>
        </div>
      </section>

      {/* Body */}
      <div className="my-8 max-w-[768px] m-auto">
        <NoticeListView />
      </div>
    </div>
  );
}

export default NoticeListPage;
