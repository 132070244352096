import { useEffect, useState } from "react";
import { INoticeModel } from "../../../../../apis/models/support/notice/noticeModel";
import { SupportService } from "../../../../../apis/services/support/supportService";
import NoticeListItem from "./NoticeListItem";

function NoticeListView() {
  const [items, setItems] = useState<INoticeModel[]>([]);

  useEffect(() => {
    findAll();
  }, []);

  const findAll = async () => {
    try {
      const response = await SupportService.findAllNotice();
      if (response.status === "OK") {
        setItems(response.data);
      }
    } catch (error) {}
  };

  return (
    <div>
      <div className="divide-y border-y border-b-custom-grey-100 divide-custom-grey-100 border-t-custom-grey-200">
        {items.map((item, index) => (
          <NoticeListItem key={index} item={item} onClick={() => {}} />
        ))}
      </div>
    </div>
  );
}

export default NoticeListView;
