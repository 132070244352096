import { INoticeModel } from "../../../../../apis/models/support/notice/noticeModel";
import { DateUtil } from "../../../../../utils/dateUtil";

type Props = {
  item: INoticeModel;
  onClick: () => void;
};

function NoticeListItem({ item, onClick }: Props) {
  return (
    <div className="flex p-4 justify-between cursor-pointer hover:bg-primary/10">
      <div>{item.title}</div>
      <div>{DateUtil.timeago(item.createdDate)}</div>
    </div>
  );
}

export default NoticeListItem;
