import { Get } from "../..";
import { ApiResponse } from "../../models/common/apiResponse";
import { CursorPaginationResponse } from "../../models/common/cursorPaginationResponse";
import { IPostCommentModel } from "../../models/post/postCommentModel";
import { IPostModel } from "../../models/post/postModel";

export const PostService = {
  /**
   * Post 전체 조회
   * @returns
   */
  findAll: async (
    params: string,
  ): Promise<ApiResponse<CursorPaginationResponse<IPostModel[]>>> => {
    return Get(`/open/api/post?${params}`);
  },
  /**
   * Post 단건 조회
   * @param id
   * @returns
   */
  findById: async (id: string): Promise<ApiResponse<IPostModel>> => {
    return Get(`/open/api/post/${id}`);
  },

  findByReply: async (
    id: string
  ): Promise<ApiResponse<Array<IPostCommentModel>>> => {
    return Get(`/open/api/post/${id}/reply`);
  },
};
