import CustomImage from "../../../components/image/CustomImage";
import { imageData } from "../../../constants/imageData";
import MarketListView from "./components/MarketListView";

function MarketPage() {
  return (
    <div>
      {/* Header */}
      <div className="bg-[#FFF1AA]">
        <section className="max-w-[1200px] m-auto h-[315px]">
          <div className="h-full">
            <div className="relative h-full max-w-[768px] m-auto">
              <CustomImage
                className="absolute bottom-0 top-0 h-full object-cover right-0 md:block hidden"
                src="https://d1unjqcospf8gs.cloudfront.net/assets/home/main/3x/fleamarket-39d1db152a4769a6071f587fa9320b254085d726a06f61d544728b9ab3bd940a.webp"
              />
              <div className="flex justify-center md:hidden block">
                <CustomImage
                  className="absolute w-[320px] h-[230px] bottom-0 "
                  src={imageData.marketImage}
                />
              </div>
              <div className="flex flex-col gap-4 md:justify-center md:pt-0 pt-6 h-full md:mx-0 mx-4">
                <p className="md:text-[32px] text-[26px] font-semibold">
                  믿을만한
                  <br />
                  라이더와 중고거래
                </p>
                <p className="md:block hidden">
                  라이더들과 가깝고 따뜻한 거래를
                  <br />
                  지금 경험해보세요.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Body */}
      <div>
        <section className="md:mt-16 mt-9 max-w-[768px] m-auto">
          <div className="flex justify-center">
            <p className="md:text-[32px] text-[26px] font-semibold">중고거래</p>
          </div>

          <div className="py-8">
            <MarketListView />
          </div>
        </section>
      </div>
    </div>
  );
}

export default MarketPage;
