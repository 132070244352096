import CustomImage from "../../../components/image/CustomImage";
import { imageData } from "../../../constants/imageData";
import CommunityListView from "./components/CommunityListView";

function CommunityPage() {
  return (
    <div>
      {/* Header */}
      <div className="bg-[#ffe2d2]">
        <section className="max-w-[1200px] m-auto h-[315px]">
          <div className="h-full">
            <div className="relative h-full max-w-[768px] m-auto">
              <CustomImage
                className="absolute bottom-0 top-0 h-full object-cover right-0 md:block hidden"
                src="https://www.daangn.com/_next/static/media/jobs_hero_3x.b118130e.png"
              />
              <div className="flex justify-center md:hidden block">
                <CustomImage
                  className="absolute w-[460px] h-auto bottom-0 "
                  src={imageData.communityImage}
                />
              </div>
              <div className="flex flex-col gap-4 md:justify-center md:pt-0 pt-6 h-full md:mx-0 mx-4">
                <p className="md:text-[32px] text-[26px] font-semibold">
                  믿을만한
                  <br />
                  라이더와 소통
                </p>
                <p className="md:block hidden">
                  라이더들과 가깝고 따뜻한 대화를
                  <br />
                  지금 경험해보세요.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Body */}
      <div>
        <section className="md:mt-16 mt-9 max-w-[768px] m-auto">
          <div className="flex justify-center">
            <p className="md:text-[32px] text-[26px] font-semibold">커뮤니티</p>
          </div>

          <div className="py-8">
            <CommunityListView />
          </div>
        </section>
      </div>
    </div>
  );
}

export default CommunityPage;
