import { IMarketModel } from "../../../../apis/models/market/marketModel";
import CustomImage from "../../../../components/image/CustomImage";

type Props = {
  item: IMarketModel;
  onClick?: () => void;
};

function MarketListItem({ item, onClick }: Props) {
  return (
    <div className="rounded-lg overflow-hidden">
      <div>
        <CustomImage
          className="aspect-square rounded-lg w-[240px] h-[240px] object-cover"
          src={item.images[0]}
        />
      </div>
      <div className="py-2 flex flex-col">
        <p className="font-light line-clamp-2">{item.title}</p>
        <p className="text-md font-semibold">
          {Intl.NumberFormat().format(item.price)}원
        </p>
      </div>
    </div>
  );
}

export default MarketListItem;
