import { useEffect, useState } from "react";
import { IFaqModel } from "../../../../apis/models/support/faq/faqModel";
import { SupportService } from "../../../../apis/services/support/supportService";
import AccordionGroup from "../../../../components/accordion/AccordionGroup";
import FaqListItem from "./FaqListItem";

function FaqListView() {
  const [items, setItems] = useState<IFaqModel[]>([]);

  useEffect(() => {
    findAll();
  }, []);

  const findAll = async () => {
    try {
      const response = await SupportService.findAllFaq();
      if (response.status === "OK") {
        setItems(response.data);
      }
    } catch (error) {}
  };

  return (
    <div className="pb-4">
      <AccordionGroup className="divide-y border-y border-b-custom-grey-100 divide-custom-grey-100 border-t-custom-grey-200">
        {items.map((item, index) => (
          <FaqListItem key={index} item={item} />
        ))}
      </AccordionGroup>
    </div>
  );
}

export default FaqListView;
