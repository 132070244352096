import React, { useEffect, useRef, useState } from "react";
import { FiChevronDown } from "react-icons/fi";

type Props = {
  title: React.ReactNode;
  open?: boolean;
  icon?: React.ReactNode;
  children: React.ReactNode;
  className?: string | undefined;
  onClick?: () => void;
};

function Accordion({
  title,
  className,
  open = false,
  children,
  onClick,
  icon = <FiChevronDown size={24} color="#212121" />,
}: Props) {
  const [isFirst, setIsFirst] = useState(true);
  const [active, setActive] = useState(open);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);

  useEffect(() => {
    if (isFirst) {
      setTimeout(() => {
        setIsFirst(false);
      }, 200);
    }
  }, []);

  useEffect(() => {
    setActive(open)
  }, [open])

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, []);

  return (
    <div className={`w-full ${className && className}`}>
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={() => {
          if(onClick) {
            onClick && onClick();
          } else {
            setActive(!active);
          }
        }}
      >
        <div className="flex-grow">{title}</div>
        <div
          className={`m-6 ${active ? "rotate-180" : "rotate-0"} ${
            isFirst ? "" : "duration-200 ease-in-out"
          }`}
        >
          {icon}
        </div>
      </div>
      <div
        style={{
          height: `${active ? contentHeight : 0}px`,
          transition: `height ${isFirst ? "" : "0.2s ease-in-out"}`,
        }}
        className={`overflow-hidden`}
      >
        <div ref={contentRef}>{children}</div>
      </div>
    </div>
  );
}

export default Accordion;
