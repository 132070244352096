import { useState } from "react";
import CustomImage from "../../../components/image/CustomImage";
import RidingListView from "./components/RidingListView";
import { imageData } from "../../../constants/imageData";

function RidingPage() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const tabs = [
    { type: "HOT_PLACE", title: "라이딩 핫플" },
    { type: "COURSE", title: "라이딩 코스" },
  ];

  return (
    <div>
      {/* Header */}
      <div className="bg-[#d2edfa]">
        <section className="max-w-[1200px] m-auto h-[315px]">
          <div className="h-full">
            <div className="relative h-full max-w-[768px] m-auto">
              <CustomImage
                className="absolute bottom-0 top-0 h-full object-cover right-0 md:block hidden"
                src="https://www.daangn.com/_next/static/media/nearby_stores_hero_image_3x.47c43e04.png"
              />
              <div className="flex justify-center md:hidden block">
                <CustomImage
                  className="absolute w-[320px] h-auto bottom-0 "
                  src={imageData.ridingImage}
                />
              </div>
              <div className="flex flex-col gap-4 md:justify-center md:pt-0 pt-6 h-full md:mx-0 mx-4">
                <p className="md:text-[32px] text-[26px] font-semibold">
                  믿을만한
                  <br />
                  라이더와 같이
                </p>
                <p className="md:block hidden">
                  라이더들과 가깝고 따뜻한 곳으로
                  <br />
                  지금 같이 라이딩해요.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Body */}
      <div>
        <section className="md:mt-16 mt-9 max-w-[768px] m-auto">
          <div className="flex justify-center">
            <p className="md:text-[32px] text-[26px] font-semibold">라이딩</p>
          </div>

          <div className="flex justify-center gap-4 mt-4">
            {tabs.map((e, i) => (
              <button
                className={`border px-4 py-1 rounded-full text-sm ${
                  currentIndex === i && "bg-neutral-600 text-white"
                }`}
                onClick={() => setCurrentIndex(i)}
              >
                {e.title}
              </button>
            ))}
          </div>
          <div className="py-8">
            {currentIndex === 0 && <RidingListView type={tabs[0].type} />}
            {currentIndex === 1 && <RidingListView type={tabs[1].type} />}
          </div>
        </section>
      </div>
    </div>
  );
}

export default RidingPage;
