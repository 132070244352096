import { BsChatLeftDots, BsHeart } from "react-icons/bs";
import { IPostModel } from "../../../../apis/models/post/postModel";
import CustomImage from "../../../../components/image/CustomImage";
import { DateUtil } from "../../../../utils/dateUtil";

type Props = {
  item: IPostModel;
  onClick?: () => void;
};

function RidingListItem({ item, onClick }: Props) {
  return (
    <div className="rounded-lg overflow-hidden shadow-lg">
      <div>
        {item.images.length > 0 && (
          <CustomImage
            className="aspect-[16/9] object-cover shrink-0"
            src={item.images[0]}
          />
        )}
      </div>
      <div className="p-2">
        <div className="flex justify-between mb-2">
          <div></div>
          <p className="text-sm">{DateUtil.timeago(item.createdDate)}</p>
        </div>
        <p className="text-lg font-semibold">{item.title}</p>
        <div className="mt-2 flex justify-between">
          <div>
            {item.address.map((e, i) => (
              <p className="text-sm">{e.address + e.addressDetail}</p>
            ))}
          </div>
          <div className="flex gap-2">
            <div className="flex items-center gap-1">
                <span><BsHeart/></span>
                <p>{item.like.count}</p>
            </div>
            <div className="flex items-center gap-1">
                <span><BsChatLeftDots /></span>
                <p>{item.comments.length}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RidingListItem;
