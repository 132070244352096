import React, { useState } from "react";

type Props = {
  children: React.ReactElement[];
  multiple?: boolean;
  className?: string | undefined;
};

function AccordionGroup({ children, className, multiple = false }: Props) {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleAccordionClick = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className={className}>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child as React.ReactElement, {
          open: openIndex === index,
          onClick: multiple ? null : () => handleAccordionClick(index),
        })
      )}
    </div>
  );
}

export default AccordionGroup;
