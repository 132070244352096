import { Link, useNavigate } from "react-router-dom";
import CustomImage from "../../components/image/CustomImage";
import { imageData } from "../../constants/imageData";

function Header() {
  const navigate = useNavigate();

  return (
    <header className="h-[64px] z-10 bg-white fixed w-screen border-b">
      <div className="max-w-[1200px] m-auto">
        <div className="relative flex justify-start mx-6 my-[12px]">
          <div
            onClick={() => {
              navigate("/");
            }}
          >
            <CustomImage
              src={imageData.logoMain}
              className="md:w-[130px] w-[100px] cursor-pointer mr-10"
            />
          </div>

          <nav>
            <ul className="flex flex-row font-semibold gap-8 my-2 md:text-[16px] text-[14px]">
              <li className="cursor-pointer hover:text-slate-500">
                <Link to={"/community"}>커뮤니티</Link>
              </li>

              <li className="cursor-pointer hover:text-slate-500">
                <Link to={"/market"}>중고거래</Link>
              </li>
              <li className="cursor-pointer hover:text-slate-500">
                <Link to={"/riding"}>라이딩</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
